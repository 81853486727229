@tailwind base;
@tailwind components;
@tailwind utilities;

/* Définir les polices Quicksand */
@font-face {
  font-family: "Quicksand";
  src: url("./assets/fonts/Quicksand/Quicksand-Regular.ttf") format("truetype");
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: "Quicksand";
  src: url("./assets/fonts/Quicksand/Quicksand-Medium.ttf") format("truetype");
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: "Quicksand";
  src: url("./assets/fonts/Quicksand/Quicksand-SemiBold.ttf") format("truetype");
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: "Quicksand";
  src: url("./assets/fonts/Quicksand/Quicksand-Bold.ttf") format("truetype");
  font-weight: 700;
  font-style: normal;
}

/* Définir les polices Caveat */
@font-face {
  font-family: "Caveat";
  src: url("./assets/fonts/Caveat/Caveat-Regular.ttf") format("truetype");
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: "Caveat";
  src: url("./assets/fonts/Caveat/Caveat-SemiBold.ttf") format("truetype");
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: "Caveat";
  src: url("./assets/fonts/Caveat/Caveat-Medium.ttf") format("truetype");
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: "Caveat";
  src: url("./assets/fonts/Caveat/Caveat-Bold.ttf") format("truetype");
  font-weight: 700;
  font-style: normal;
}

/* Appliquer Quicksand globalement */
* {
  font-family: "Quicksand", sans-serif;
}

body {
  font-family: "Quicksand", sans-serif;
}
