

.h1__home {
  opacity: 1;
  transform: translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg)
    rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg);
  transform-style: preserve-3d;
}

.transition-opacity-fast {
  transition: opacity 0.3s ease-in-out; /* Durée : 0.3s, Fonction : ease-in-out */
}

.transition-opacity-delay {
  transition: opacity 0.3s ease-in-out delay-0.5s; /* Délai : 0.5s */
}



.hero_content{
    min-height: calc(100vh - 96px);
}




@media screen and (min-height:765px){
    

    
    .hero_content{
        min-height: calc(743px );        
    }
}