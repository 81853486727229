.title {
  font-weight: bold;
  margin: 10px 0 0 0;
  position: relative;
  padding-bottom: 20px;
}
.title::before {
  position: absolute;
  content: "";
  height: 3px;
  width: 80px;
  background-color: #202647;
  bottom: 0;
  left: 0;
  right: 35px;
  margin: auto;
}

.title::after {
  position: absolute;
  content: "";
  height: 3px;
  width: 32px;
  background-color: #0667b6;
  bottom: 0;
  margin: 0 auto 0;
  left: 95px;
  right: 0;
}

.icon {
  display: inline-block;
  width: 65px;
  height: 65px;
  text-align: center;
  line-height: 65px;
  border: 2px dashed;
  border-radius: 50%;
  font-size: 22px;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.group:hover .icon {
  border-color: white;
  color: white;
}
.default-btn-one {
  text-transform: capitalize;
  -webkit-transition: 0.5s;
  transition: 0.5s;
  font-size: 16px;
  font-weight: 600;
}

@media only screen and (max-width: 767px) {
  .page-title-area {
    padding-top: 70px;
    padding-bottom: 70px;
  }
}
.page-title-area {
  position: relative;
  z-index: 1;
  background-image: url("../assets/background1.jpg");
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
  padding-top: 100px;
  padding-bottom: 100px;
}
