
/* Définir globalement la police Nunito pour les h1 en semibold */

.title * {
  font-family: "Caveat", sans-serif;
  font-size: calc(1.625rem + 4.5vw);
  line-height: calc(1.625rem + 4.5vw);
}

.title-product {
  font-size: calc(1.625rem + 2vw);
  line-height: calc(1.625rem + 2vw);
  font-family: "Caveat", sans-serif;
  background-image: url("../assets/utils/green_highlight_03.svg");
  background-position: 10% bottom center;
  background-repeat: no-repeat;
  background-size: contain;
  white-space: nowrap;
}

.title-prod {
  font-size: calc(1.625rem + 3vw);
  line-height: calc(1.625rem + 3vw);
  font-family: "Caveat", sans-serif;
}

.title-prod * {
  font-size: calc(1.625rem + 3vw);
  line-height: calc(1.625rem + 3vw);
  font-family: "Caveat", sans-serif;
}
.title-pro {
  font-size: calc(1.625rem + 4.5vw);
  line-height: calc(1.625rem + 4.5vw);
  background-image: url("../assets/utils/yellow_highlight_bold_04.svg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  white-space: nowrap;
}

.form_container {
  content: "";
  width: 120px;
  height: 120px;
  background-image: url("../assets/utils/corner_1.svg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
}

@media screen and (max-width: 480px) {
  .title-pro {
    font-size: 50px;
    line-height: 50px;
  }

  .title * {
    font-size: 70px;
    line-height: 70px;
  }
}
